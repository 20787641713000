import { Map, tileLayer, layerGroup, Util, DomUtil, Control, control, LatLngBounds, Point } from 'leaflet'
import { closemap } from '../control/Control.CloseMap'
import { pgpoint } from '../layer'
import { global } from '../constants/global'
import { PGRoute } from '../routing/PGRoute'
import '../styles/pgroute.css'

const streetsLayerURL = 'https://tiles.tcol.it/WM/256/WST/{z}/{x}/{z}_{x}_{y}.webp'
//const streetsLayerURL = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
const satelliteLayerURL = 'https://1.aerial.maps.api.here.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/256/png8?app_id=mlwsdOSgJXBLLqcezPbO&app_code=y3W_f1TlQp5eLa6qv9YtXQ&utm=32'

/*
 * @class PGMappy
 * @aka IOLMap.PGMappy
 * @inherits Map
 *
 * The central class of the API — it is used to create a map on a page and manipulate it.
 *
 * @example
 *
 * ```js
 * // initialize the map on the "map" div with a given center and zoom and two base layer street a satellite
 * let map = new IOLMap.PGMappy('map', {  oppure let map = new PGMappy('map', {
 * 	lat: 45.505,
 *  lon: 9.13
 * 	zoom: 13
 * });
 * ```
 *
 */
export const PGMappy = Map.extend({
  options: {
    // @option nameContainer
    nameContainer: undefined,
    // @option latitude
    lat: undefined,
    // @option longitute
    lon: undefined,
    // @option zoom INT default 5
    z: 5,
    // @option zoom INT default 5
    minZoom: 5,
    // @option wheelZoomEnabled INT default 1
    wheelZoomEnabled: 1,
    // @option lng INT lingua, default 1
    lng: 1,
    // @option mapWidth
    mapWidth: undefined,
    // @option mapHeight
    mapHeight: undefined,
    // @option defaultLevelControl
    defaultLevelControl: false,
    // @option defaultSelectedLayers ["Mappa", "Satellite", "Traffico"];
    defaultSelectedLayers: ['Mappa'],
    // @option offsetX
    offsetRight: 0,
    // @option offsetY
    offsetTop: 0,
    // @option mapType INT Tipo di mappa da visualizzare (default PGMAP_TYPE_MAP).
    // I valori possibili sono: PGMAP_TYPE_MAP (modalità mappa semplice), PGMAP_TYPE_ORTO (modalità ortofoto)
    mapType: global.PGMAP_TYPE_MAP,
    // @option sito String
    sito: undefined,
    // @option closableMap: Boolean = true
    // If true add a close button to the map
    closableMap: true,
    // @option onCloseCallback: function = undefined
    // Callback invoked when the close button is clicked/touched
    onCloseCallback: undefined,
    // @option PGPoints: Array = []
    // Markers to add on map initialization
    PGPoints: undefined
  },

  trafficLayerURL: 'https://services.tuttocitta.it/gentile{s}?col={x}&row={y}&z={z}&vpt=wm&zone=32&fr=i&type=1&id=0UodYN8bB8&sito=TC_WEB',

  initialize: function (options) {
    // set current options
    Util.setOptions(this, options)

    // call parent constructor
    Map.prototype.initialize.call(this, options.nameContainer, options)

    // Remove default zoom control
    this.zoomControl.remove()

    // Add new zoom control at 'bottomright'
    this.customZoomControl = control
      .zoom({
        position: 'bottomright'
      })
      .addTo(this)

    // add trademark
    this._addTrademark()

    // add layers
    this.baseLayers = {}

    // add PGPoints array
    this.PGPoints = []

    // Aggiungo le funzionalita di routing
    this.pgRoute = null
    this.routePathLayerGroup = null
    this.routePointsLayerGroup = null

    //this._addCloseControl()

    this._addInitialLayers(options)

    // Imposta la dimensione della mappa
    if (options.mapWidth && options.mapHeight) {
      this.getContainer().style.width = options.mapWidth
      this.getContainer().style.height = options.mapHeight
    }

    // Disabilita lo zoom con il mouse se passato come parametro 0
    if (options.wheelZoomEnabled === 0) {
      this.scrollWheelZoom.disable()
    }

    // Aggiunge i PGPoint se ve ne sono e centra la mappa sul loro baricentro se non è stato passato
    // lat lon iniziale
    if (this.options.PGPoints) {
      this._addPGPoints()
      if (!this.options.lat || !this.options.lon) {
        this.getRouteCenter(this.options.PGPoints)
      } else {
        this.setCenterAndZoom(this.options.lon, this.options.lat, this.options.z)
      }
    } else if (this.options.lat && this.options.lon) {
      this.setCenterAndZoom(this.options.lon, this.options.lat, this.options.z)
    }
  },

  _addCloseControl: function () {
    if (this.options.closableMap) {
      this.options.onCloseCallback =
        this.options.onCloseCallback ||
        function (e) {
          console.log('Close map handler')
        }

      closemap({ closeCallback: this.options.onCloseCallback }).addTo(this)
    }
  },

  _addInitialLayers: function () {
    this.baseLayers.streets = tileLayer(streetsLayerURL, {
      minZoom: this.options.minZoom,
      maxZoom: 18,
      id: 'iol-streets'
    })
    if (this.options.mapType === global.PGMAP_TYPE_MAP) {
      this.baseLayers.streets.addTo(this)
    }

    this.baseLayers.satellite = tileLayer(satelliteLayerURL, {
      minZoom: this.options.minZoom,
      maxZoom: 18,
      id: 'iol-satellite'
    })
    if (this.options.mapType === global.PGMAP_TYPE_ORTO) {
      this.baseLayers.satellite.addTo(this)
    }

    // Layer del traffico
    this.baseLayers.traffic = tileLayer(this.trafficLayerURL, {
      minZoom: this.options.minZoom,
      maxZoom: 18,
      id: 'iol-traffic',
      subdomains: ['1', '2']
    })

    // Creare il layer group "Mappa + Traffico"
    var mapAndTrafficGroup = layerGroup([this.baseLayers.streets, this.baseLayers.traffic])
    if (this.options.mapType === global.PGMAP_TYPE_TRAFFIC) {
      mapAndTrafficGroup.addTo(this)
    }

    // Aggiungi il controllo per lo switch tra i layer
    var selectedLayers = this.options.defaultSelectedLayers
    this.layersControl = control.layers(Object.assign(selectedLayers.includes('Mappa') && { Mappa: this.baseLayers.streets }, selectedLayers.includes('Satellite') && { Satellite: this.baseLayers.satellite }, selectedLayers.includes('Traffico') && { Traffico: mapAndTrafficGroup }), null, {
      position: 'bottomleft'
    })

    this.on('baselayerchange', () => {
      this.baseLayers.traffic.setUrl(this.trafficLayerURL)
    })

    // Aggiunge il controllo allo switch
    if (this.options.defaultLevelControl) {
      this.layersControl.addTo(this)
    }

    // Permette lo zoom massimo solo su certi bound di alcune citta principali
    this.on('zoom', (event) => {
      const targetZoom = this.getZoom()
      if (targetZoom < 18) return

      const center = this.getCenter()
      // Verifica se il centro è all'interno di almeno uno dei bounds
      if (!global.boundsArrayZ18.some((bound) => new LatLngBounds(bound.southWest, bound.northEast).contains(center))) {
        this.setZoom(17, { animate: false })
        this.fire('zoomend')
      }
    })
  },

  _addPGPoints: function () {
    if (this.options.PGPoints.length > 0) {
      this.options.PGPoints.forEach((m) => {
        this.pointAdder(pgpoint(m))
      })
    }
  },

  _addTrademark: function () {
    const attributionControl = this.attributionControl
    this.removeControl(attributionControl)
    const CustomControl = Control.extend({
      options: {
        position: 'bottomright'
      },
      onAdd: function () {
        const container = DomUtil.create('div', 'my-custom-control')
        const currentYear = new Date().getFullYear()
        container.innerHTML = `© ${currentYear} HERE | Tuttocittà`
        container.style.bottom = '-88px'
        return container
      }
    })
    this.addControl(new CustomControl())
  },

  // Aggiunge un PGPoint alla mappa
  pointAdder: function (pgpoint) {
    pgpoint.addTo(this)
    this.PGPoints.push(pgpoint)
  },

  // Nasconde il control level
  levelControlHide: function () {
    if (this.layersControl) this.layersControl.getContainer().style.display = 'none'
  },

  // Mostra il control level
  levelControlShow: function () {
    if (this.layersControl) this.layersControl.getContainer().style.display = 'block'
  },

  // Cambia visualizzazione della mappa
  toggleLayer: function () {
    if (this.hasLayer(this.baseLayers.streets)) {
      this.removeLayer(this.baseLayers.streets)
      this.baseLayers.satellite.addTo(this)
    } else if (this.hasLayer(this.baseLayers.satellite)) {
      this.removeLayer(this.baseLayers.satellite)
      this.baseLayers.streets.addTo(this)
    }
    if (this.hasLayer(this.baseLayers.traffic)) {
      this.removeLayer(this.baseLayers.traffic)
      this.baseLayers.traffic.addTo(this)
    }
  },

  // toggle traffic layer realtime
  toggleLayerTraffic: function () {
    if (this.hasLayer(this.baseLayers.traffic)) {
      this.removeLayer(this.baseLayers.traffic)
    } else {
      this.baseLayers.traffic.setUrl(this.trafficLayerURL)
      this.baseLayers.traffic.addTo(this)
    }
  },

  // toggle traffic layer realtime
  addLayerTraffic: function () {
    if (this.hasLayer(this.baseLayers.traffic)) {
      return
    } else {
      this.baseLayers.traffic.setUrl(this.trafficLayerURL)
      this.baseLayers.traffic.addTo(this)
    }
  },

  // toggle traffic layer realtime
  removeLayerTraffic: function () {
    if (this.hasLayer(this.baseLayers.traffic)) {
      this.removeLayer(this.baseLayers.traffic)
    }
  },

  // toggle traffic layer in specific day and hour
  toggleLayerTrafficData: function (gg, hh) {
    if (this.hasLayer(this.baseLayers.traffic)) {
      this.removeLayer(this.baseLayers.traffic)
    } else {
      this.baseLayers.traffic.setUrl(this.trafficLayerURL + '&date=' + gg + '%3B' + hh)
      this.baseLayers.traffic.addTo(this)
    }
  },

  // Nasconde il control zoom
  customZoomControlHide: function () {
    this.customZoomControl.getContainer().style.display = 'none'
    const element = document.querySelector('.my-custom-control')
    element.style.bottom = '-13px'
  },

  // Mostra il control zoom
  customZoomControlShow: function () {
    this.customZoomControl.getContainer().style.display = 'block'
    const element = document.querySelector('.my-custom-control')
    element.style.bottom = '-88px'
  },

  // Rimuove uno specifico PGPoint dalla mappa
  removePGPoint: function (pgpoint) {
    pgpoint.remove()
  },

  // Rimuove tutti i PGPoint dalla mappa (non quelli del percorso)
  removePGPoints: function () {
    this.PGPoints.forEach((pgpoint) => {
      pgpoint.remove()
    })
  },

  // Centra la mappa in un punto con un dato zoom
  setCenterAndZoom: function (lon, lat, zoom) {
    const centerPoint = this.project([lat, lon], zoom)

    const offsetPoint = new Point(centerPoint.x - this.options.offsetRight / 2, centerPoint.y - this.options.offsetTop / 2)

    const newCenter = this.unproject(offsetPoint, zoom)

    this.setView(newCenter, zoom)
  },

  // Rettifica il bound sulla base degli offset della mappa offsetRight e offsetTop
  adjustedFitBounds: function (bounds) {
    const offsetRight = -2 * this.options.offsetRight
    const offsetTop = 2 * this.options.offsetTop
    const currentNorthLat = bounds.getNorth()
    const currentSouthLat = bounds.getSouth()
    const currentWestLng = bounds.getWest()
    const currentEastLng = bounds.getEast()

    const pixelHeight = this.getSize().y
    const pixelWidth = this.getSize().x

    const latOffset = (currentNorthLat - currentSouthLat) * (offsetTop / pixelHeight)
    const lngOffset = (currentEastLng - currentWestLng) * (offsetRight / pixelWidth)

    const newNorthLat = currentNorthLat + latOffset
    const newSouthLat = currentSouthLat - latOffset
    const newWestLng = currentWestLng + lngOffset
    const newEastLng = currentEastLng

    const adjustedBounds = new LatLngBounds([newSouthLat, newWestLng], [newNorthLat, newEastLng])
    return adjustedBounds
  },

  // Centra la mappa sul baricentro di un array di PGPoints con il corretto livello di zoom
  getRouteCenter: function (pgpoints) {
    const arrayOfLatLngs = pgpoints.map(function (item) {
      return { lat: item.lat, lon: item.lon }
    })
    const bounds = new LatLngBounds(arrayOfLatLngs)
    let adjustedFitBounds = map.adjustedFitBounds(bounds)
    this.fitBounds(adjustedFitBounds)
  },

  // Metodo per calcolare un percorso
  getRoute(routeParams, callback, fromMovePin = false) {
    if (fromMovePin) this.removeRoutePath()
    else this.removeRoute()

    this.pgRoute = new PGRoute(routeParams, callback)

    return this.pgRoute.getRoute(this).then(() => {
      this.addRouteText()
    })
  },

  // Metodo che disegna tutti i percorsi del route
  drawRoute(routeParams, route) {
    this.pgRoute = new PGRoute(routeParams)
    this.pgRoute.drawRoute(route, this)
  },

  // Metodo che disegna un percorso
  addRoute(routeList) {
    this.pgRoute.addRoute(routeList, this)
  },

  // Metodo che aggiunge gli eventi ad un percorso
  addEventRoute(selHeaderRoute = '[data-alt]', selDetailRoute = '[data-lat][data-lon]', selRouteClass = 'suggested-path-selected') {
    this.pgRoute.addEventRoute(this, (selHeaderRoute = '[data-alt]'), (selDetailRoute = '[data-lat][data-lon]'), (selRouteClass = 'suggested-path-selected'))
  },

  // Metodo che aggiunge gli eventi alla testata di un percorso
  addEventRouteHeader(selHeaderRoute = '[data-alt]', selDetailRoute = '[data-lat][data-lon]', selRouteClass = 'suggested-path-selected') {
    this.pgRoute.addEventRouteHeader(this, (selHeaderRoute = '[data-alt]'), (selDetailRoute = '[data-lat][data-lon]'), (selRouteClass = 'suggested-path-selected'))
  },

  // Metodo che aggiunge gli eventi al dettaglio di un percorso
  addEventRouteDetails(selHeaderRoute = '[data-alt]', selDetailRoute = '[data-lat][data-lon]') {
    this.pgRoute.addEventRouteDetails(this, (selHeaderRoute = '[data-alt]'), (selDetailRoute = '[data-lat][data-lon]'))
  },

  // Metodo che inserisce il percorso testuale all'interno dell'elemento html id
  addRouteText() {
    this.pgRoute.addRouteText(this)
  },

  // Metodo che rimuove il percorso (path e points)
  removeRoute() {
    if (this.routePathLayerGroup) {
      this.removeRoutePath()
      this.removeLayer(this.routePointsLayerGroup)
      this.routePointsLayerGroup = null
    }
  },

  // Metodo che rimuove il path del percorso e rende null pgRoute
  removeRoutePath() {
    this.cleanRoute()
    this.pgRoute = null
  },

  // Metodo che rimuove il path del percorso
  cleanRoute() {
    if (this.routePathLayerGroup) {
      this.removeLayer(this.routePathLayerGroup)
      this.routePathLayerGroup = null
      if (this.pgRoute.routeParams.textOpts) {
        this.pgRoute.textEl.innerHTML = '&nbsp;'
      }
    }
    if (this.pgRoute) {
      this.pgRoute.polylines = []
      this.pgRoute.route = null
    }
  }
})

// PGMappy disponibile a livello globale per compatibilita vecchie API
window.PGMappy = PGMappy
