// export * from './constants/global'
// export * from '../node_modules/leaflet/dist/leaflet-src.esm';
// export * from './control/index';
// export * from './layer/index';
// export * from './event/index';
// export * from './map/index';

import '../node_modules/leaflet/dist/leaflet.css'
import { global } from './constants/global'
import * as L from 'leaflet'
import * as Markercluster from 'leaflet.markercluster'
import * as control from './control/index'
import * as layer from './layer/index'
import * as event from './event/index'
import * as map from './map/index'
import * as routing from './routing/index'
import * as geocoder from './geocoder/index'
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

const IOLMap = {
  ...global,
  ...L,
  ...Markercluster,
  ...control,
  ...layer,
  ...event,
  ...map,
  ...routing,
  ...geocoder
}

export default IOLMap
